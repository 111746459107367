<template>
  <div class="declare-container">
    <div class="filter">
      <van-sticky>
        <!--    筛选条件-->
        <van-dropdown-menu active-color="#1989fa">
          <van-dropdown-item title-class="dropdownTitle" @change="onFilterLevyChange" v-model="formData.levyId" :options="levyBody" />
          <van-dropdown-item title-class="dropdownTitle" @change="onFilterYearChange" v-model="formData.year" :options="years" />
        </van-dropdown-menu>
      </van-sticky>
    </div>
    <div v-if="listData.length >0" class="declare">
      <div class="declareItem" v-for="item in listData" :key="item.id" @click="onGoDetail(item)">
        <div class="declareItemLine declareItemLineJustify">
          <div class="declareName">{{ item.name }}</div>
          <div class="declareMonth">{{ item.monthDay }}</div>
        </div>
        <div class="declareItemLine declareItemLineLeft">
          <div>所得项目：</div>
          <div>{{ item.itemName }}</div>
        </div>
        <div class="declareItemLine declareItemLineLeft">
          <div>应纳税所得额：</div>
          <div>{{ item.taxBasis }}</div>
        </div>
        <div class="declareItemLine declareItemLineLeft">
          <div>扣缴义务人名称：</div>
          <div>{{ item.levyName }}</div>
        </div>
      </div>
    </div>
    <van-empty v-else description="没有更多数据了" />
    <footer-menu></footer-menu>
  </div>
</template>
<script>
import footerMenu from '@/components/footer/FooterMenu'
import requestHTAPI from '@/axios/HantangTax'
export default {
  name: 'declare',
  components: {
    footerMenu
  },
  data () {
    return {
      listData: [],
      formData: {
        pageNum: 1,
        pageSize: 100, // 不需要分页
        levyId: '',
        year: `${new Date().getFullYear()}`
      },

      levyBody: [],
      years: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.$toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true
      })

      // 初始化代征主体
      requestHTAPI.queryLevyBodyInfos().then(res => {
        if (res.data.code == '0000') {
          const { data } = res.data

          if (data.length > 0) {
            // 有代征主体
            data.map(item => { item.text = item.name; item.value = item.id })
            this.levyBody = data

            requestHTAPI.findIdCard().then(res => {
              if (res.data.code == '0000') {
                this.$toast.clear()
                const flag = res.data.data // 0-未提交过信息， 1-已提交过且信息完整， 2-已提交过信息且不完整
                if (flag === '0') {
                  this.$dialog.alert({
                    title: '提示',
                    message: '请编辑并提交我的信息后即可查看'
                  }).then(() => {
                    this.$router.replace({ path: '/mine/userInfo' })
                  })
                } else if (flag === '1') {
                  const levyId = sessionStorage.getItem('LEVY')
                  const year = sessionStorage.getItem('YEAR')
                  this.$set(this.formData, 'levyId', Number(levyId) || (data.length > 0 ? data[0].id : ''))
                  this.$set(this.formData, 'year', year || `${new Date().getFullYear()}`)

                  // 初始化年
                  for (let year = 2020; year <= this.formData.year; year++) {
                    this.years.push({
                      text: `${year}年`,
                      value: `${year}`
                    })
                  }
                  this.getList()
                } else {
                  this.$dialog.alert({
                    title: '提示',
                    message: '请补充上传身份证正反面照片后即可查看'
                  }).then(() => {
                    this.$router.replace({ path: '/mine/userInfo' })
                  })
                }
              }
            })
          } else {
            // 无代征主体

          }
        }
      })
    },

    onFilterLevyChange (value) {
      sessionStorage.setItem('LEVY', value)
      this.getList()
    },
    onFilterYearChange (value) {
      sessionStorage.setItem('YEAR', value)
      this.getList()
    },
    getList () {
      requestHTAPI.queryDetailByIdCard({
        ...this.formData
      }).then(res => {
        console.log(res, '获取明细成功')
        this.loading = false
        if (res.data.code === '0000') {
          this.listData = res.data.data.rows
        } else {
          this.finished = true
          this.$notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    onGoDetail (args) {
      this.$toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true
      })

      this.$router.push(
        {
          path: '/mine/declareDetail',
          query: {
            args: JSON.stringify(args)
          }
        },
        () => {
          this.$toast.clear(true)
        }
      )
    }
  },
  mounted () {

  },

  // 因jssdk兼容问题导致 keep-alive 失效， 现已sessionStorage实现缓存页面状态
  beforeRouteLeave (to, from, next) {
    if (to.name != 'declareDetail') {
      sessionStorage.removeItem('LEVY')
      sessionStorage.removeItem('YEAR')
      next()
    } else {
      next()
    }
  }
}
</script>
<style scoped lang="less">
  .declare-container{
    height: calc(100% - 100px);
    .filter {
      margin-bottom: 10px;
      /deep/ .van-cell__title {
        flex: 5 !important;
      }
      /deep/ .van-dropdown-menu__bar {
        padding: 0 22px !important;
        /deep/ .van-dropdown-menu__title {
          padding: 0 !important;
          padding-right: 24px !important;
        }
        /deep/ .van-dropdown-menu__title::after {
          right: 0 !important;
        }
        /deep/ .van-dropdown-menu__item:first-child {
          flex: 2 !important;
          -webkit-flex: 2 !important;
          justify-content: flex-start !important;
          -webkit-justify-content: flex-start !important;
        }
        /deep/ .van-dropdown-menu__item:last-child {
          flex: 1 !important;
          -webkit-flex: 1 !important;
          justify-content: flex-end !important;
          -webkit-justify-content: flex-end !important;
        }
      }
    }
    .declare {
      width: 100%;
      text-align: left;

      .declareItem {
        margin: 40px 20px;
        padding: 14px 18px;
        box-shadow: 0 0 10px 0px rgba(0,0,0,.2);
        border-radius: 10px;
        .declareItemLine {
          padding: 8px 0;
        }
        .declareItemLineJustify {
          display: flex;
          justify-content: space-between;
          font-size: 32px;
          font-weight: 500;
        }
        .declareItemLineLeft {
          display: flex;
          justify-content: flex-start;
          font-size: 28px;
          font-weight: 400;
        }
      }
    }
  }
</style>
