<template>
  <div class="footer-menu">
    <van-tabbar router v-model="tabbarActive" :active-color="activeColor" :inactive-color="unActiveColor" placeholder>
<!--      <van-tabbar-item name="index" icon="home-o" :to="{name:'Index'}">首页</van-tabbar-item>-->
      <van-row class="tab-sub-box">
        <van-col span="16" style="border-right: 1px solid #ececec">
          <van-tabbar-item class="width1" name="taskHome" icon="label-o" :to="{name:'TaskHome'}">项目广场</van-tabbar-item>
        </van-col>
        <van-col span="8">
          <van-tabbar-item class="" name="mine" icon="contact"  @click="show = true">我的</van-tabbar-item>
        </van-col>
      </van-row>
    </van-tabbar>

    <van-action-sheet
      v-model="show"
      :actions="actions"
      close-on-click-action
      @select="onSelect"
      @close="onCancel"
    />
  </div>
</template>
<script>
export default {
  name: 'FooterMenu',
  data () {
    return {
      tabbarActive: 'index',
      show: false,
      activeColor: '#1989fa',
      unActiveColor: '#7d7e80',
      actions: [
        { name: '我的项目', path: '/mine/myPro' },
        { name: '上传交付物', path: '/mine/uploadDeliverables' },
        { name: '结算记录', path: '/mine/settlement' },
        { name: '申报明细', path: '/mine/declare' },
        { name: '我的信息', path: '/mine/userInfo' },
        { name: '关于我们', path: '/mine/about' }
      ]
    }
  },
  created () {
    this.initTabbarIndex()
  },
  methods: {
    initTabbarIndex () {
      const url = this.$route.path
      if (url.indexOf('/taskHome') > -1) {
        this.tabbarActive = 'taskHome'
      }
      if (url.indexOf('/mine') > -1) {
        this.tabbarActive = 'mine'
      }
    },
    onSelect (item) {
      this.show = false
      this.$router.push({
        path: item.path
      })
    },
    onCancel () {
      const that = this
      that.initTabbarIndex()
    }
  },
  watch: {
    $route (nval, oval) {
      this.initTabbarIndex()
    }
  }

}
</script>
<style scoped>
  .tab-sub-box{
    width: 100%;
    display: flex;
    align-items: center;
  }
</style>
